import * as React from 'react';

export const MonoLogo: React.FC<React.SVGProps<any>> = (props) => (
  <svg viewBox="0 0 380 119" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.2901 40.3278C45.3275 35.2904 52.2879 32.1739 59.9777 32.1739C67.6466 32.1739 74.5911 35.2765 79.6246 40.29L79.6454 21.7502C73.7514 18.7143 67.0623 17 59.9777 17C52.8891 17 46.1653 18.9309 40.2692 21.9679L40.2901 40.3278Z"
      fill="var(--logo-red)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.6454 79.6712C74.6079 84.7096 67.6506 87.8251 59.9608 87.8251C52.2918 87.8251 45.3473 84.7236 40.3099 79.709L40.29 98.2498C46.1871 101.285 52.8732 103 59.9608 103C67.0493 103 73.7374 101.285 79.6315 98.2488L79.6454 79.6712Z"
      fill="var(--logo-orange)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M40.3278 79.6961C35.2904 74.6586 32.1739 67.7012 32.1739 60.0114C32.1739 52.3425 35.2755 45.399 40.29 40.3616L21.7492 40.3417C18.7143 46.2378 17 52.9229 17 60.0114C17 67.1 18.7143 73.7881 21.7522 79.6831L40.3278 79.6961Z"
      fill="var(--logo-purple)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M79.6344 40.289C84.6718 45.3255 87.7883 52.2868 87.7883 59.9766C87.7883 67.6456 84.6867 74.5901 79.6712 79.6225L98.213 79.6434C101.248 73.7493 102.962 67.0612 102.962 59.9766C102.962 52.8871 101.248 46.198 98.211 40.302L79.6344 40.289Z"
      fill="var(--logo-green)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M156.184 60.0025C156.184 51.2543 150.066 44.2264 141.159 44.2264C132.25 44.2264 126.134 51.2543 126.134 60.0025C126.134 68.7507 132.25 75.7816 141.159 75.7816C150.066 75.7816 156.184 68.7507 156.184 60.0025ZM121.303 60.0025C121.303 48.5205 129.621 40.0436 141.159 40.0436C152.695 40.0436 161.014 48.5205 161.014 60.0025C161.014 71.4875 152.695 79.9674 141.159 79.9674C129.621 79.9674 121.303 71.4875 121.303 60.0025Z"
      fill="var(--logo-black)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M179.514 75.5401C185.021 75.5401 188.564 71.4 188.564 66.0585C188.564 60.717 185.021 56.575 179.514 56.575C174.009 56.575 170.468 60.717 170.468 66.0585C170.468 71.4 174.009 75.5401 179.514 75.5401ZM166.22 53.3064H170.468V57.0102H170.578C172.922 54.2853 176.027 52.6506 179.732 52.6506C187.746 52.6506 193.14 58.32 193.14 66.0585C193.14 73.6887 187.527 79.4645 180.388 79.4645C175.808 79.4645 172.157 77.1749 170.578 74.286H170.468V90.87H166.22V53.3064Z"
      fill="var(--logo-black)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M217.773 63.7679C217.61 59.4629 214.773 56.575 210.087 56.575C205.402 56.575 202.297 59.4629 201.752 63.7679H217.773ZM221.426 74.1796C218.425 78.0484 214.83 79.4645 210.087 79.4645C202.077 79.4645 197.173 73.4711 197.173 66.0585C197.173 58.157 202.732 52.6506 210.253 52.6506C217.449 52.6506 222.349 57.5568 222.349 66.0049V67.3673H201.752C202.077 71.7797 205.456 75.5401 210.087 75.5401C213.737 75.5401 216.192 74.2333 218.207 71.6723L221.426 74.1796Z"
      fill="var(--logo-black)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M227.854 59.3565C227.854 57.0102 227.638 54.9959 227.638 53.3064H231.669C231.669 54.6689 231.779 56.083 231.779 57.5012H231.889C233.033 54.9959 236.195 52.6506 240.389 52.6506C247.094 52.6506 250.088 56.9029 250.088 63.0603V78.8086H245.84V63.4986C245.84 59.2462 243.984 56.575 240.007 56.575C234.504 56.575 232.104 60.554 232.104 66.3865V78.8086H227.854V59.3565Z"
      fill="var(--logo-black)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M259.211 41.0085H265.919L278.8 70.7909H278.907L291.889 41.0085H298.438V78.9994H293.932V47.1242H293.826L280.192 78.9994H277.454L263.825 47.1242H263.718V78.9994H259.211V41.0085Z"
      fill="var(--logo-black)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M312.392 57.7516H316.415C321.407 57.7516 327.902 57.7516 327.902 51.47C327.902 45.9992 323.126 45.1943 318.725 45.1943H312.392V57.7516ZM307.886 41.0085H317.597C324.949 41.0085 332.729 42.0798 332.729 51.47C332.729 56.9973 329.135 60.5948 323.234 61.5071L334.018 78.9994H328.493L318.189 61.9364H312.392V78.9994H307.886V41.0085Z"
      fill="var(--logo-black)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M358.123 47.7125C356.617 45.2986 353.936 44.2264 351.197 44.2264C347.224 44.2264 343.097 46.1056 343.097 50.6113C343.097 54.1005 345.028 55.6577 351.091 57.5906C356.994 59.4688 362.468 61.3997 362.468 68.858C362.468 76.4246 355.973 79.9674 349.108 79.9674C344.706 79.9674 339.983 78.5174 337.408 74.7063L341.27 71.5412C342.88 74.3347 346.1 75.7816 349.375 75.7816C353.237 75.7816 357.638 73.5277 357.638 69.18C357.638 64.5112 354.473 63.7063 347.71 61.4524C342.344 59.6805 338.268 57.3183 338.268 50.989C338.268 43.6907 344.545 40.0436 351.197 40.0436C355.438 40.0436 359.033 41.2222 361.822 44.4947L358.123 47.7125Z"
      fill="var(--logo-black)"
    />
  </svg>
);
